import { cx, css, keyframes } from '@emotion/css';
import React, { useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, styleMixins } from '@grafana/ui';
import { HelmholtzBranding } from 'app/_helmholtz/HelmholtzBranding';

import { Branding } from '../Branding/Branding';
import { BrandingSettings } from '../Branding/types';
import { Footer } from '../Footer/Footer';

import { LoginHeader } from './LoginHeader';
import { LoginInfoBox } from './LoginInfoBox';
import { LoginNavigation } from './LoginNavigation';
import { LoginNewsBox } from './LoginNewsBox';
import { ProjectDetailOverlay } from './ProjectDetailOverlay';

interface InnerBoxProps {
  enterAnimation?: boolean;
}
export const InnerBox = ({ children, enterAnimation = true }: React.PropsWithChildren<InnerBoxProps>) => {
  const loginStyles = useStyles2(getLoginStyles);
  return <div className={cx(loginStyles.loginInnerBox, enterAnimation && loginStyles.enterAnimation)}>{children}</div>;
};

export interface LoginLayoutProps {
  /** Custom branding settings that can be used e.g. for previewing the Login page changes */
  branding?: BrandingSettings;
  isChangingPassword?: boolean;
}

export const LoginLayout = ({ children, branding, isChangingPassword }: React.PropsWithChildren<LoginLayoutProps>) => {
  const loginStyles = useStyles2(getLoginStyles);
  const [startAnim, setStartAnim] = useState(false);

  useEffect(() => setStartAnim(true), []);

  const openProjectDetails = (event: React.MouseEvent) => {
    event.preventDefault();
    const target = event.target;
    const overlay = document.getElementById('project-overlay');

    if (overlay) {
      const iframe = overlay.getElementsByTagName('iframe')[0];
      // @ts-ignore
      iframe.setAttribute('src', target.dataset.url);
      overlay.style.display = 'block';
    }
  };

  return (
    <Branding.LoginBackground className={cx(loginStyles.container, startAnim && loginStyles.loginAnim)}>
      <LoginHeader />
      <LoginNavigation />
      <h2 className={cx(loginStyles.sectionHeader)}>Visualize, monitor and analyse your data</h2>
      <section className={cx(loginStyles.row)}>
        <div className={cx(loginStyles.loginContent, Branding.LoginBoxBackground(), 'login-content-box')}>
          <div style={HelmholtzBranding.DatahubLogo} />
          <h5>
            Please sign in with your master credentials below <u>or</u> use the Helmholtz AAI (Sign in with OAuth).
          </h5>
          <div className={loginStyles.loginOuterBox}>{children}</div>
        </div>
        <LoginInfoBox>
          <h2>About</h2>
          <p>
            The aim of this service is to provide access to time series data. It is a tool of the Datahub, an initiative
            of the Helmholtz centres in the Research Field Earth & Environment, enhancing the research data
            infrastructure and tools to improve the accessibility, interoperability and re-usability of research data
            across scientific institutions.
          </p>
          <p>
            It enables users to search, compare, visualize and download relevant data from available collections of
            observational data. In order to achieve these goals, the Observatory View will be under continuous
            development and extended with further components.
          </p>
          <p>
            The Datahub provides a variety of other tools. Please visit the&nbsp;
            <a href="https://datahub.erde-und-umwelt.de/" target="_blank" rel="noreferrer">
              Datahub Homepage
            </a>
            &nbsp;for more information.
          </p>
        </LoginInfoBox>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxNews}>
          <h2>News</h2>
          <LoginNewsBox />
        </LoginInfoBox>
      </section>
      <h2 className={cx(loginStyles.sectionHeader)}>Selected Projects</h2>
      <section className={cx(loginStyles.row)}>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxProject1}>
          <figcaption>&copy; UFZ / MOSES</figcaption>
        </LoginInfoBox>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxProject2}>
          <figcaption>&copy; GFZ / TERENO</figcaption>
        </LoginInfoBox>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxProject3}>
          <figcaption>&copy; GFZ / Ch. Voigt</figcaption>
        </LoginInfoBox>
      </section>
      <section className={cx(loginStyles.row)}>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxNoBg}>
          <h3>MOSES</h3>
          <p>
            MOSES is a novel observing system of the Helmholtz Association, developed by the Helmholtz Centres in the
            research field “Earth and Environment”. It comprises highly flexible and mobile observation modules which
            are specifically designed to investigate the interactions of short-term events and long-term trends across
            Earth compartments.
            <br />
            <br />
            Heat waves, hydrological extremes, ocean eddies and permafrost thaw will be in the focus of this new
            event-oriented observation and research initiative.
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={cx(loginStyles.projectLink)}
              onClick={openProjectDetails}
              data-url="/help/projects/moses"
              href="#"
            >
              Show examples from project
            </a>
          </p>
        </LoginInfoBox>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxNoBg}>
          <h3>TERENO North-East</h3>
          <p>
            Global change has triggered a number of environmental changes, such as alterations in climate, land
            productivity, water resources, atmospheric chemistry, and ecological systems.
            <br />
            <br />
            The main goal of the infrastructure measure TERENO is to create observation platforms on the basis of an
            interdisciplinary and long-term aimed research program with a close cooperation between several facilities
            of the Helmholtz-Gemeinschaft for the investigation of consequences of Global Change for terrestrial
            ecosystems and the socioeconomic implications.
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={cx(loginStyles.projectLink)}
              onClick={openProjectDetails}
              data-url="/help/projects/tereno"
              href="#"
            >
              Show examples from project
            </a>
          </p>
        </LoginInfoBox>
        <LoginInfoBox style={HelmholtzBranding.LoginInfoBoxNoBg}>
          <h3>HELGOG</h3>
          <p>
            The Helgoland Gravimetric Observatory Germany (HELGOG) was established in March 2020 in the basement of the
            Biologische Anstalt Helgoland (BAH) of the Alfred Wegener Institute AWI, Helmholtz Centre for Polar and
            Marine Research.
            <br />
            <br />
            The main research focus is on the analysis of tidal and non-tidal ocean loading in the North Sea for the
            validation and calibration of the satellite mission GRACE Follow-On. This observatory includes the
            superconducting gravimeter iGrav 047. The superconducting gravimeter data are available for registered users
            from the IGETS data base hosted by GFZ.
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={cx(loginStyles.projectLink)}
              onClick={openProjectDetails}
              data-url="/help/projects/helgog"
              href="#"
            >
              Show examples from project
            </a>
          </p>
        </LoginInfoBox>
      </section>
      <Footer />
      <ProjectDetailOverlay />
    </Branding.LoginBackground>
  );
};

const flyInAnimation = keyframes`
from{
  opacity: 0;
  transform: translate(-60px, 0px);
}

to{
  opacity: 1;
  transform: translate(0px, 0px);
}`;

export const getLoginStyles = (theme: GrafanaTheme2) => {
  const bgColor = '#0a2d6e';

  return {
    container: css({
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: bgColor,
      minWidth: '100%',
      marginLeft: 0,
    }),
    row: css({
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      marginTop: '30px',
    }),
    loginAnim: css`
      &:before {
        opacity: 1;
      }

      .login-content-box {
        opacity: 1;
      }
    `,
    submitButton: css`
      justify-content: center;
      width: 100%;
    `,
    loginLogo: css`
      width: 100%;
      max-width: 60px;
      margin-bottom: 15px;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        max-width: 100px;
      }
    `,
    loginLogoWrapper: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: ${theme.spacing(3)};
    `,
    titleWrapper: css`
      text-align: center;
    `,
    mainTitle: css`
      font-size: 22px;

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        font-size: 32px;
      }
    `,
    subTitle: css`
      font-size: ${theme.typography.size.md};
      color: ${theme.colors.text.secondary};
    `,
    loginContent: css`
      max-width: 478px;
      width: calc(100% - 2rem);
      display: flex;
      align-items: stretch;
      flex-direction: column;
      position: relative;
      justify-content: flex-start;
      z-index: 1;
      min-height: 464px;
      border-radius: ${theme.shape.borderRadius(4)};
      padding: ${theme.spacing(2, 0)};
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
      margin: 0 20px;

      h5 {
        font-family: 'Helvetica';
        padding: 15px 50px 0 48px;
      }

      @media ${styleMixins.mediaUp(theme.v1.breakpoints.sm)} {
        min-height: 464px;
        justify-content: flex-start;
      }
    `,
    loginOuterBox: css`
      display: flex;
      overflow-y: hidden;
      align-items: center;
      justify-content: center;
    `,
    loginInnerBox: css`
      padding: ${theme.spacing(2)};

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      max-width: 415px;
      width: 100%;
      transform: translate(0px, 0px);
      transition: 0.25s ease;
    `,
    enterAnimation: css`
      animation: ${flyInAnimation} ease-out 0.2s;
    `,
    sectionHeader: css`
      font-family: 'Helvetica';
      text-align: center;
      margin-top: 60px;
    `,
    projectLink: css`
      display: block;
      margin-top: 20px;
    `,
  };
};
